import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Slideshow from '../components/Slideshow/Slideshow';
import MenuSection from '../components/MenuSection/MenuSection';
import MenuSection2 from '../components/MenuSection2/MenuSection2';

export const Head = () => {
  return (
    <Seo
      title="デザート・お土産"
      description="肉のながおかのデザート・お土産をご紹介いたします。お土産 惣菜 和牛肉いなり ¥1,800等。"
    />
  );
};

const DessertPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <Slideshow
          title="Dessert"
          subtitle="デザート・お土産"
          images={[
            { id: 2, src: '/nagaoka2023/image/top2.png', alt: 'Image 2' },
            { id: 3, src: '/nagaoka2024/t101.jpg', alt: 'Image 3' },
            { id: 1, src: '/nagaoka2024/t104.jpg', alt: 'Image 1' },
          ]}
        />
        <MenuSection
          title="自家製アイスクリーム"
          subtitle="Ice Cream"
          items={[
            {
              id: 1,
              title: 'Vanilla',
              price: '¥680',
            },
            {
              id: 2,
              title: 'Pistachio',
              price: '¥800',
            },
            {
              id: 3,
              title: 'Chocolate',
              price: '¥700',
            },
            {
              id: 4,
              title: 'Orange Glasse',
              price: '¥1,100',
            },
            {
              id: 5,
              title: 'Milk',
              price: '¥1,800',
            },
            {
              id: 6,
              title: '京都宇治抹茶',
              price: '¥780',
            },
            {
              id: 7,
              title: '塩麹',
              price: '¥800',
            },
            {
              id: 8,
              title: 'ひのひかり(お米)',
              price: '¥600',
            },
            {
              id: 9,
              title: '丹波きな粉',
              price: '¥800',
            },
            {
              id: 10,
              title: '季節のフルーツ',
              price: '¥600',
            },
          ]}
        />
        <MenuSection2
          title="自家製チョコレートテリーヌ"
          subtitle="Terrine"
          imageSrc="/nagaoka2023/image/dessert.png"
          firstItems={[
            {
              id: 1,
              title: 'EatIn',
              price: '¥1,000',
            },
          ]}
          secondItems={[
            {
              id: 1,
              title: 'TakeOut',
              price: '¥1,800',
            },
          ]}
        />
        <MenuSection2
          title="メッセージプレート"
          subtitle="Message plate"
          imageSrc="/nagaoka2024/menu/messege.png"
          firstTitle="Mサイズ"
          firstItems={[
            {
              id: 1,
              title: 'コースプレート',
              price: '¥1,500',
            },
            {
              id: 2,
              title: '単品プレート',
              price: '¥1,800',
            },
          ]}
          secondTitle="Lサイズ"
          secondItems={[
            {
              id: 1,
              title: 'コースプレート',
              price: '¥2,500',
            },
            {
              id: 2,
              title: '単品プレート',
              price: '¥2,800',
            },
          ]}
        />
        <MenuSection
          title="お土産 惣菜"
          subtitle="Souvenur"
          items={[
            {
              id: 1,
              title: '和牛肉いなり',
              price: '¥1,800',
            },
            {
              id: 2,
              title: '和牛肉ちらし',
              price: '¥3,600',
            },
            {
              id: 3,
              title: '和牛ロースカツサンド',
              price: '¥2,800',
            },
            {
              id: 4,
              title: '和牛ヒレカツサンド',
              price: '¥4,200',
            },
            {
              id: 5,
              title: '和牛ビーフシチュー(5食)',
              price: '¥5,500',
            },
            {
              id: 6,
              title: '和牛デミグラスハンバーグ(5食)',
              price: '¥7,500',
            },
          ]}
        />
        <MenuSection
          title="お土産 ドレッシング・ソース"
          imageSrc="/nagaoka2024/n101.jpg"
          subtitle="Dressing&Sauce"
          items={[
            {
              id: 1,
              title: '国産たまねぎドレッシング',
              price: '¥600',
            },
            {
              id: 2,
              title: '国産にんじんドレッシング',
              price: '¥600',
            },
            {
              id: 3,
              title: 'ステーキソース',
              price: '¥650',
            },
            {
              id: 4,
              title: '特性ポン酢',
              price: '¥700',
            },
            {
              id: 5,
              title: '特性味噌だれ',
              price: '¥700',
            },
          ]}
        />
        <MenuSection
          title="お土産 ローストビーフ"
          subtitle="RoastBeef"
          items={[
            {
              id: 1,
              title: '和牛サーロイン500g 3〜4人前 ローストビーフ（タレ付き）',
              price: '¥8,500',
            },
            {
              id: 2,
              title: '和牛もも 500g 3〜4人前 ローストビーフ（タレ付き）',
              price: '¥6,800',
            },
          ]}
        />
        <MenuSection
          title="お持ち帰り専用肉"
          subtitle="TakeOutBeef"
          items={[
            {
              id: 1,
              title: '和牛ヒレステーキ 100g',
              price: '¥5,000',
            },
            {
              id: 2,
              title: '和牛サーロインステーキ 100g',
              price: '¥4,000',
            },
            {
              id: 3,
              title: '和牛赤身ステーキ 100g',
              price: '¥2,500',
            },
            {
              id: 4,
              title: 'すき焼き用 和牛サーロイン 100g',
              price: '¥4,000',
            },
            {
              id: 5,
              title: 'すき焼き用 和牛赤身 100g',
              price: '¥2,500',
            },
          ]}
        />
      </Layout>
    </div>
  );
};

export default DessertPage;
