// src/components/MenuSectionGridThree/MenuSectionGridThree.tsx

import React from 'react';
import ScrollAnimation from '../ScrollAnimation/ScrollAnimation';
import FormattedText from '../commons/FormattedText/FormattedText';
import * as styles from './MenuSection.module.css';

type ColorType = 'primary' | 'secondary';

type Item = {
  title: string;
  price: string;
};

type MenuSectionProps = {
  title: string;
  colorType: ColorType;
  firstItems: Item[];
  firstTitle: string;
  secondItems: Item[];
  secondTitle: string;
  sectionId: string;
  subtitle: string;
  description: string;
  imageSrc: string;
  imageAlt: string;
};

const MenuSection2: React.FC<MenuSectionProps> = ({
  title,
  colorType,
  firstItems,
  firstTitle,
  secondItems,
  secondTitle,
  sectionId,
  subtitle,
  description,
  imageSrc,
  imageAlt,
}) => {
  let featureSectionClass = styles.featureSectionPrimary;
  if (colorType === 'primary') {
    featureSectionClass = styles.featureSectionPrimary;
  } else if (colorType === 'secondary') {
    featureSectionClass = styles.featureSectionSecondary;
  }
  return (
    <ScrollAnimation>
      <section className={featureSectionClass} id={sectionId}>
        <div className={styles.featureSectionInner}>
          <h2 className={styles.head}>
            <span className={styles.headText}>{title}</span>
            {subtitle && <span className={styles.headSubtext}>{subtitle}</span>}
          </h2>
          {description && (
            <p className={styles.headDescription}>
              <FormattedText text={description} />
            </p>
          )}
          <div className={styles.grid}>
            <img className={styles.image} src={imageSrc} alt={imageAlt} />
            <div className={styles.items}>
              {firstTitle && <h3 className={styles.itemTitle}>{firstTitle}</h3>}
              {firstItems.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.content}>
                    <h3 className={styles.title}>{item.title}</h3>
                    <p className={styles.price}>{item.price}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.items}>
              {secondTitle && (
                <h3 className={styles.itemTitle}>{secondTitle}</h3>
              )}
              {secondItems.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.content}>
                    <h3 className={styles.title}>{item.title}</h3>
                    <p className={styles.price}>{item.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default MenuSection2;
