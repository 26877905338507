// extracted by mini-css-extract-plugin
export var content = "MenuSection-module--content--a8b24";
export var cord = "MenuSection-module--cord--a7943";
export var featureSection = "MenuSection-module--featureSection--7ea7f";
export var featureSectionInner = "MenuSection-module--featureSectionInner--9fefd";
export var featureSectionPrimary = "MenuSection-module--featureSectionPrimary--8e665";
export var featureSectionSecondary = "MenuSection-module--featureSectionSecondary--9adaf";
export var grid = "MenuSection-module--grid--2def2";
export var head = "MenuSection-module--head--7e5f3";
export var headDescription = "MenuSection-module--headDescription--f8a91";
export var headImage = "MenuSection-module--headImage--86dd9";
export var headSubtext = "MenuSection-module--headSubtext--697a5";
export var headText = "MenuSection-module--headText--26cf3";
export var image = "MenuSection-module--image--74ca9";
export var price = "MenuSection-module--price--7d4df";
export var title = "MenuSection-module--title--592d1";