// extracted by mini-css-extract-plugin
export var content = "MenuSection-module--content--fb126";
export var cord = "MenuSection-module--cord--8eee7";
export var featureSection = "MenuSection-module--featureSection--f2eae";
export var featureSectionInner = "MenuSection-module--featureSectionInner--d7532";
export var featureSectionPrimary = "MenuSection-module--featureSectionPrimary--fdc16";
export var featureSectionSecondary = "MenuSection-module--featureSectionSecondary--1dfe2";
export var grid = "MenuSection-module--grid--7e71e";
export var head = "MenuSection-module--head--75226";
export var headDescription = "MenuSection-module--headDescription--0e26b";
export var headSubtext = "MenuSection-module--headSubtext--51f76";
export var headText = "MenuSection-module--headText--eab71";
export var image = "MenuSection-module--image--589f6";
export var itemTitle = "MenuSection-module--itemTitle--7295d";
export var items = "MenuSection-module--items--27e55";
export var price = "MenuSection-module--price--810aa";
export var title = "MenuSection-module--title--c0f2b";